import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
import MobileView from "../components/Bio/MobileView";
import DesktopView from "../components/Bio/DesktopView";


const BioPage = (
  {
    data: {
      markdownRemark: {
        html,
        frontmatter: {
          selfie: {
            childImageSharp: {fluid: fluidSelfie}
          }
        }
      },
      openBookMobile: {
        childImageSharp: {fluid: fluidMobile}
      },
      openBookDesktop: {
        childImageSharp: {fluid: fluidDesktop}
      }
    },
    breakpoints: {mobile, tablet, desktop}
  }) => {

  const isMobileOrTablet = mobile || (tablet && !desktop);

  return (
    <Layout>
      {isMobileOrTablet
        ? (<MobileView fluidMobile={fluidMobile} fluidSelfie={fluidSelfie} html={html}/>)
        : (<DesktopView fluidDesktop={fluidDesktop} html={html}/>) }
    </Layout>
  )
};

const ImageProps = PropTypes.shape({
  childImageSharp: PropTypes.shape({fluid: PropTypes.object})
});

BioPage.propTypes = {
  markdownRemark: PropTypes.shape({
    html: PropTypes.string,
    frontmatter: PropTypes.shape({
      selfie: ImageProps
    })
  }),
  openBookMobile: ImageProps,
  openBookDesktop: ImageProps
};

export default withBreakpoints(BioPage)

export const BioPageQuery = graphql`
  query BioPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html,
      frontmatter {
        selfie {
          childImageSharp {
            fluid(maxWidth: 950) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    openBookDesktop: file(relativePath: { eq: "bio/open-book-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    openBookMobile: file(relativePath: { eq: "notebook-background.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
