import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import { HTMLContent } from "../Content";

const StyledTextMobile = styled.div`
  padding: 10%;
`;
const StyledImage = styled.div`
  padding-bottom: 20%;
  padding: 5% 5% 0% 1%;
`;

const MobileView = ({fluidMobile, fluidSelfie, html}) => (
  <BackgroundImage Tag="div" fluid={fluidMobile}>
    <StyledImage>
      <Img  fadeIn={false} loading="eager" fluid={fluidSelfie} />
    </StyledImage>
    <StyledTextMobile className="is-size-4">
      <HTMLContent content={html}/>
    </StyledTextMobile>
  </BackgroundImage>
);

export default MobileView
