import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { HTMLContent } from "../Content";

const StyledContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledColumns = styled.div`
  height: 100%;
`;

const StyledTextDesktop = styled.div`
  padding: 10% 15% 10% 20%;
`;

const DesktopView = ({fluidDesktop, html}) => (
  <div className="is-relative">
    <Img  fadeIn={false} loading="eager" fluid={fluidDesktop} />
    <StyledContent>
      <StyledColumns className='columns is-vcentered'>
        <div className='column is-half'>
          <StyledTextDesktop className="is-size-4">
            <HTMLContent content={html}/>
          </StyledTextDesktop>
        </div>
      </StyledColumns>
    </StyledContent>
  </div>
);

export default DesktopView
